import { Grid, Typography, Box, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const CustomRadio = ({ icon, title, description, selected, onClick }) => {
  const theme = useTheme();

  const handleClick = () => {
    if (onClick) {
      onClick(title);
    }
  };

  return (
    <Grid item lg={6} md={6} xs={12}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "space-between",
          padding: "16px",
          border: `1px solid ${selected ? theme.palette.primary.main : "#ccc"}`,
          borderRadius: "8px",
          backgroundColor: selected ? theme.palette.primary.light : "#ffffff",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: selected
              ? theme.palette.primary
              : theme.palette.action.hover,
            color: selected ? theme.palette.primary.contrastText : "#000000",
          },
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ marginRight: "16px" }}>{icon}</Box>

          <Box direction="column" alignItems="flex-start" sx={{ flex: 1 }}>
            <Typography variant="h6" fontSize={"15px"}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              color={selected ? "text" : "textSecondary"}
              fontSize={"13px"}
              sx={{ wordWrap: "break-word" }}
            >
              {description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

CustomRadio.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  initialSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CustomRadio;
