import { DatePicker } from "@mui/x-date-pickers";

const DateInputField = ({
  disabled,
  error,
  helperText,
  label,
  name,
  onChange,
  value,
  sx,
  additionalProps = {},
}) => (
  <DatePicker
    sx={sx}
    slotProps={{ textField: { error, helperText } }}
    disabled={disabled}
    helperText={helperText}
    label={label}
    value={value}
    onChange={onChange}
    openTo="year"
    views={["year", "month", "day"]}
    yearsOrder="desc"
    format="DD/MM/YYYY"
    name={name}
    {...additionalProps}
  />
);

export default DateInputField;
