import { TextField, InputAdornment } from "@mui/material";
import { formatRut } from "utils/_MRutils/stringHelpers";
import { getNestedValue } from "utils/_MRutils/objectHelpers";
import Select from "./Fields/Select";
import LazySelect from "./Fields/LazySelect";
import CustomRadioGroup from "./Fields/CustomRadioGroup";
import DateInputField from "./Fields/DateInputField";
import AddressAutocomplete from "./Fields/AddressAutocomplete";
import PhoneField from "./Fields/PhoneField";

const FormField = ({ fieldConfig, formik }) => {
  const {
    name,
    label,
    type,
    options,
    disabled = false,
    helperText,
    action,
    actionCallback,
    actionDefaultValue,
    props,
    relatedFields = [],
  } = fieldConfig;
  const value = getNestedValue(formik.values, name);
  const error =
    getNestedValue(formik.touched, name) &&
    Boolean(getNestedValue(formik.errors, name));
  const displayedHelperText =
    (getNestedValue(formik.touched, name) &&
      getNestedValue(formik.errors, name)) ||
    helperText ||
    "";

  const handleChange = (event) => {
    let formattedValue = event?.target?.value || null;

    if (type === "rut") {
      formattedValue = formatRut(event.target.value);
      if (formattedValue === "-") formattedValue = "";
    }
    if (type === "date") {
      formattedValue = event;
    }
    if (type === "mapsAutocomplete" && formattedValue) {
      relatedFields.forEach((fieldName) => {
        formik.setFieldValue(fieldName, formattedValue[fieldName]);
      });
      return formik.setFieldValue(name, formattedValue.formatted_address);
    }

    formik.setFieldValue(name, formattedValue || "");
  };

  const getType = () => {
    switch (type) {
      case "rut":
        return "text";
      case "currency":
        return "number";
      default:
        return type;
    }
  };

  const getInputProps = () => {
    if (type === "currency") {
      return {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      };
    }
    return {};
  };

  const renderField = () => {
    switch (type) {
      case "customRadio":
        return (
          <CustomRadioGroup
            id={name}
            name={name}
            label={label}
            value={value}
            error={error}
            disabled={disabled}
            onChange={handleChange}
            displayedHelperText={displayedHelperText}
            options={options}
          />
        );
      case "lazySelect":
        return (
          <LazySelect
            id={name}
            name={name}
            label={label}
            value={value}
            error={error}
            disabled={disabled}
            onChange={handleChange}
            displayedHelperText={displayedHelperText}
            action={action}
            actionCallback={actionCallback}
            actionDefaultValue={actionDefaultValue}
          />
        );
      case "select":
        return (
          <Select
            id={name}
            name={name}
            label={label}
            value={value}
            error={error}
            disabled={disabled}
            onChange={handleChange}
            displayedHelperText={displayedHelperText}
            options={options}
          />
        );
      case "date":
        return (
          <DateInputField
            name={name}
            label={label}
            value={value}
            error={error}
            disabled={disabled}
            onChange={handleChange}
            helperText={displayedHelperText}
            additionalProps={props}
          />
        );
      case "mapsAutocomplete":
        return (
          <AddressAutocomplete
            name={name}
            label={label}
            value={value}
            error={error}
            disabled={disabled}
            onChange={handleChange}
            helperText={displayedHelperText}
            additionalProps={props}
          />
        );
      case "phone":
        return (
          <PhoneField
            name={name}
            label={label}
            value={value}
            disabled={disabled}
            handleChange={handleChange}
          />
        );
      case "rut":
      case "currency":
      case "text":
      case "email":
      default:
        return (
          <TextField
            fullWidth
            id={name}
            name={name}
            label={label}
            disabled={disabled}
            type={getType()}
            value={value}
            onChange={handleChange}
            error={error}
            helperText={displayedHelperText}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={getInputProps()}
          />
        );
    }
  };

  return renderField();
};

export default FormField;
