import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, Box, Typography } from "@mui/material";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js";

/**
 * Campo de teléfono específico para Chile con formato y validación.
 *
 * @param {string} name - Nombre del campo para el formulario
 * @param {string} label - Etiqueta del campo
 * @param {boolean} disabled - Si el campo está deshabilitado
 * @param {string} value - Valor del número de teléfono
 * @param {Function} handleChange - Función para manejar cambios
 * @returns {React.Component} Componente de campo de teléfono chileno
 */
export default function PhoneField({
  name,
  label = "Número de teléfono",
  disabled = false,
  value = "",
  handleChange,
}) {
  const [phoneInput, setPhoneInput] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const COUNTRY = "CL"; // Chile fijo

  // Inicializar el campo cuando cambia el valor externamente
  useEffect(() => {
    if (value) {
      try {
        // Manejar tanto números con prefijo "56" como con "+56"
        let processedValue = value;
        if (value.startsWith("+")) {
          processedValue = value.substring(1); // Quitar el "+" si existe
        }

        if (processedValue.startsWith("56")) {
          // Si empieza con "56", quitar el código de país
          setPhoneInput(processedValue.substring(2));
          validateNumber(processedValue.substring(2));
        } else {
          // Si no empieza con "56", usarlo como está
          setPhoneInput(processedValue);
          validateNumber(processedValue);
        }
      } catch (err) {
        // Si hay algún error, simplemente usar el valor como está
        setPhoneInput(value);
      }
    } else {
      setPhoneInput("");
      setError(false);
      setHelperText("");
    }
  }, [value]);

  // Validar número de teléfono (específicamente para Chile)
  const validateNumber = (number) => {
    if (!number) {
      setError(false);
      setHelperText("");
      return true;
    }

    // Crear un número para validar
    const phoneNumberToValidate = `+56${number}`;

    if (
      !isValidPhoneNumber(phoneNumberToValidate, { defaultCountry: COUNTRY })
    ) {
      setError(true);
      // Mensaje específico para números chilenos
      setHelperText(
        "Ingresa un número chileno válido (9 dígitos comenzando con 9)"
      );
      return false;
    } else {
      setError(false);
      setHelperText("");
      return true;
    }
  };

  // Manejar cambios en el input
  const onPhoneInputChange = (e) => {
    const inputValue = e.target.value;

    // Solo permitir dígitos
    const digitsOnly = inputValue.replace(/\D/g, "");

    // Formatear mientras escribe
    const formatter = new AsYouType(COUNTRY);
    const formattedValue = formatter.input(digitsOnly);

    // Obtener solo la parte del número nacional
    const nationalNumber = formatter.getNumber()?.nationalNumber || digitsOnly;
    setPhoneInput(formattedValue);

    // Validar el número
    const isValid = validateNumber(nationalNumber);

    // Si hay una función handleChange, llamarla con el número sin el prefijo "+"
    if (handleChange) {
      try {
        // Verificar si el campo está vacío
        if (!formattedValue || formattedValue.trim() === "") {
          handleChange({
            target: {
              name,
              value: "",
            },
          });
          return;
        }

        // Devolver el número sin el prefijo "+"
        const outputNumber = isValid ? `56${nationalNumber}` : formattedValue;

        handleChange({
          target: {
            name,
            value: outputNumber,
          },
        });
      } catch (err) {
        handleChange({
          target: {
            name,
            value: formattedValue,
          },
        });
      }
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        fullWidth
        label={label}
        name={name}
        value={phoneInput}
        onChange={onPhoneInputChange}
        disabled={disabled}
        error={error}
        helperText={helperText}
        placeholder="9 XXXX XXXX"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                🇨🇱
                <Typography sx={{ fontWeight: "medium" }}>+56</Typography>
              </Box>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
